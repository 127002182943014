import React, { useRef, useEffect } from 'react';
import { Modal, Box, Typography, Grid, Button } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import qrLogo from "../../assets/qr-logo.png"
import jsPDF from 'jspdf';

const QRCodeModal = ({ open, onClose, url, text, logoSrc }) => {
    const qrRef = useRef();
    const logoRef = useRef(new Image());

    useEffect(() => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            const ctx = canvas.getContext('2d');

            const textWidth = ctx.measureText(text).width;
            const textHeight = 16;
            ctx.fillStyle = 'white';
            ctx.fillRect(
                (canvas.width - textWidth) / 2 - 10,
                canvas.height / 2 - textHeight / 2,
                textWidth + 20,
                textHeight + 4
            );
            ctx.font = 'bold 16px Arial';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText(text || 'Terra Charge', canvas.width / 2, canvas.height / 2 + 8);

            if (logoSrc) {
                const logo = new Image();
                logo.src = logoSrc;
                logo.onload = () => {
                    const logoSize = 50;
                    ctx.drawImage(
                        logo,
                        (canvas.width - logoSize) / 2,
                        (canvas.height - logoSize) / 2 - 30,
                        logoSize,
                        logoSize
                    );
                };
            }
        }
    }, [text, logoSrc]);

    const handleDownload = () => {
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = 1200;
        tempCanvas.height = 1200;
        const ctx = tempCanvas.getContext('2d');

        const qrCodeCanvas = qrRef.current.querySelector('canvas');
        ctx.drawImage(qrCodeCanvas, 0, 0, 1200, 1200);

        const logo = new Image();
        logo.src = qrLogo;
        logo.onload = () => {
            const logoSize = 200;
            const logoX = (tempCanvas.width - logoSize) / 2;
            const logoY = (tempCanvas.height - logoSize) / 2;

            ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);

            const pngUrl = tempCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            const downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `${text || 'qr-code'}.png`;
            downloadLink.click();
        };
    };


    const printQRCode = async () => {
        await new Promise((resolve) => {
            if (logoRef.current.complete) {
                resolve();
            } else {
                logoRef.current.onload = resolve;
            }
        });

        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = 1200;
        tempCanvas.height = 1200;
        const ctx = tempCanvas.getContext('2d');

        const qrCodeCanvas = qrRef.current.querySelector('canvas');
        const qrCodeDataUrl = qrCodeCanvas.toDataURL('image/png');

        const loadImage = (src) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.src = src;
            });
        };

        try {
            const qrCodeImg = await loadImage(qrCodeDataUrl);

            ctx.drawImage(qrCodeImg, 0, 0, 1200, 1200);

            const logoSize = 200;
            const logoX = (tempCanvas.width - logoSize) / 2;
            const logoY = (tempCanvas.height - logoSize) / 2;
            ctx.drawImage(logoRef.current, logoX, logoY, logoSize, logoSize);

            const imgUrl = tempCanvas.toDataURL('image/png');

            const printWindow = window.open('', '_blank');

            if (imgUrl) {
                printWindow.document.write(`
                    <html>
                        <head>
                            <title>Print QR Code</title>
                            <style>
                                body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; }
                                img { width: 100%; max-width: 600px; height: auto; }
                            </style>
                        </head>
                        <body>
                            <img src="${imgUrl}" alt="QR Code" />
                        </body>
                    </html>
                `);

                printWindow.document.close();

                setTimeout(() => {
                    printWindow.focus();
                    printWindow.print();
                    printWindow.close();
                }, 500);
            }
        } catch (error) {
            console.error('Error loading images:', error);
        }
    };



    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="qr-code-modal-title"
            aria-describedby="qr-code-modal-description"
        >
            <Box
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    backgroundColor: 'white',
                    padding: 16,
                    borderRadius: 8,
                    boxShadow: 24,
                }}
            >
                <Typography id="qr-code-modal-title" variant="h6" component="h2">
                    QR Code
                </Typography>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: 16, position: 'relative' }}
                    ref={qrRef}
                >
                    <QRCode value={url} size={256} ecLevel="Q" logoWidth={"50"} logoImage={qrLogo} />
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    style={{ marginTop: 16, marginRight: 16 }}
                >
                    Download
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={printQRCode}
                    style={{ marginTop: 16 }}
                >
                    Print
                </Button>
            </Box>
        </Modal>
    );
};

export default QRCodeModal;