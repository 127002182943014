import { useSubscription } from "@apollo/client";
import UnitConsumed from "../components/dashboard/icons/UnitConsumed";
import { API_UNITS_CONSUMED_SUBSCRIPTION, API_UNITS_CONSUMED_WITH_ALL_FACILITY_SUBSCRIPTION } from "../graphql/query";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const UnitsConsumed = ({ dateFilter, facilityFilter }) => {

    const api = facilityFilter?.name === 'All' ? API_UNITS_CONSUMED_WITH_ALL_FACILITY_SUBSCRIPTION : API_UNITS_CONSUMED_SUBSCRIPTION;
    const variables = {
        variables: {
            start: dateFilter?.start?.toISOString(),
            end: dateFilter?.end?.toISOString(),
            facility_id: facilityFilter?.id
        }
    }

    const { data } = useSubscription(api, variables);
    const [totalUnitsConsumed, setTotalUnitsConsumed] = useState(0);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();
    const [id, setId] = useState(facilityFilter?.id)

    useEffect(() => setId(facilityFilter?.id), [facilityFilter])


    useEffect(() => {
        setTotalUnitsConsumed(0);
    }, [id, dateFilter])

    useEffect(() => {
        setLoading(true);
        if (
            data?.view_facility_revenue_aggregate?.aggregate?.sum ||
            data?.view_facility_revenue_aggregate?.aggregate?.sum?.units_consumed === 0 ||
            data?.view_facility_revenue_aggregate?.aggregate?.sum?.units_consumed
        ) {
            let units_consumed = (data?.view_facility_revenue_aggregate?.aggregate?.sum?.units_consumed ?? 0);
            units_consumed = units_consumed === 0 ? '0' : (units_consumed / 1000).toFixed(3);

            if (/^[0-9.]+$/.test(units_consumed)) {
                setLoading(false)
                setTotalUnitsConsumed(units_consumed);
            }
        }
    }, [data, facilityFilter, dateFilter]);

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            bgcolor={"white"}
            justifyContent={"space-between"}
            borderRadius={"16px"}
            padding={"24px"}
            height='130px'
        >
            <Typography>
                <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('unitsConsumed')} {loading ?  (<span style={{ color: '#467CEB' }}> {t('loading')}...</span>) : ''}</Typography>
                <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>{totalUnitsConsumed} kWh</Typography>
            </Typography>
            <UnitConsumed />
        </Box>
    );
}
export default UnitsConsumed;