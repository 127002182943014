import { BarChart } from "@mui/x-charts/BarChart";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { generateRevenueChartPayload } from "./RevenueChart";
import { isLessThanTwoMonths } from "./TransactionChart";
import { useTranslation } from 'react-i18next';

const chartSetting = {
  height: 372,
};

const UnitsConsumedChart = ({ dateFilter, facilityFilter, data }) => {

  const valueFormatter = (value) => `${value?.toFixed(2)}kWh`;
  const [unitsConsumed, setUnitsConsumed] = useState([{ created_at: 0, units_consumed: 0 }]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [id, setId] = useState(facilityFilter?.id)

  useEffect(() => setId(facilityFilter?.id), [facilityFilter])


  useEffect(() => {
    setUnitsConsumed([{ created_at: 0, units_consumed: 0 }]);
  }, [id, dateFilter])

  const generateMessage = (dates) => {
    if (dates?.start && dates?.end) {
      if (isLessThanTwoMonths(new Date(dates?.start), new Date(dates?.end)))
        return `${t('perDay')}`;
      return `${t('perMonth')}`;
    }
  }

  useEffect(() => {
    setLoading(true);
    if (data?.view_facility_revenue) {
      const details = generateRevenueChartPayload(data?.view_facility_revenue, dateFilter?.start?.toISOString(), dateFilter?.end?.toISOString(), facilityFilter, true);
      setUnitsConsumed(details?.length > 0 ? details : [{ created_at: 0, units_consumed: 0 }]);
      setLoading(false);
    }
  }, [data, dateFilter, facilityFilter])


  return (
    <Box bgcolor={"white"} p={3} borderRadius={"16px"} padding={"24px 24px 8px"}>
      <Typography variant="subtitle" fontSize='20px' fontWeight='600'>
        {t('unitsConsumed')} {generateMessage(dateFilter)}
        {loading ?  (<span style={{ color: '#467CEB' }}> {t('loading')}...</span>) : ''}
      </Typography>
      {
        unitsConsumed?.length > 0 &&
        <Grid container flexDirection='column'>
          <Grid item display='flex'>
            <Grid item xs={0.5} display='flex' alignItems='center' sx={{ transform: 'rotate(-90deg)', textAlign: 'center' }} fontSize='12px' fontWeight='600' textTransform='uppercase'>
              {t('unitsConsumed')}
            </Grid>
            <Grid item xs={11.5}>
              <BarChart
                dataset={unitsConsumed}
                xAxis={[{ dataKey: "created_at", scaleType: "band" }]}
                yAxis={[{ dataKey: "units_consumed" }]}
                series={[{ dataKey: "units_consumed", valueFormatter, color: '#467CEB' }]}
                layout="vertical"
                {...chartSetting}
              />
            </Grid>
          </Grid>
          <Grid item display='flex' justifyContent='center' fontSize='12px' fontWeight='600' textTransform='uppercase'>
            {t('date')}
          </Grid>
        </Grid>
      }
    </Box>
  );
}
export default UnitsConsumedChart;